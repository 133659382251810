import React from 'react'
import { Route, BrowserRouter, Switch } from 'react-router-dom'
import { Login } from './auth/Login'
import { Relatorio } from './relatorio'
import { Provider } from 'react-redux'
import { store } from './common/ReduxStore'

function App () {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/relatorio/:id" component={Relatorio} />
        </Switch>
      </BrowserRouter>
    </Provider>
  )
}

export default App
