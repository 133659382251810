import React from 'react'

function TituloRelatorioSvg () {
  return (
    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.63 0.84C12.27 0.33 11.67 0 11 0H0L5 7L0 13.99H11C11.67 13.99 12.27 13.67 12.63 13.16L17 7L12.63 0.84ZM11 12H3.89L7.46 7L3.89 2H11L14.55 7L11 12Z" fill="#2F80ED" />
    </svg>
  )
}

export default TituloRelatorioSvg
