import { Card, Container, Header } from 'semantic-ui-react'
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import axios from 'axios'
import format from 'date-fns/format'
import BlocoConteudo from './BlocoConteudo'

const API_URL = process.env.REACT_APP_API_URL

const Titulo = styled(Header)`
  font-family: Rubik !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 100% !important;
  color: #2D2D2D !important;
`

const TituloPlaca = styled('span')`
  color: #2f80ed !important;
`

const Relatorio = () => {
  const [relatorio, setRelatorio] = useState<any>(null)
  const { id } = useParams<{ id }>()

  const updateRelatorio = React.useCallback(async () => {
    try {
      const result = await axios.get(`${API_URL}/instalacoes/id/${id}`)
      setRelatorio(result.data)
    } catch (e) {
      console.log(e)
    }
  }, [setRelatorio, id])

  useEffect(() => {
    updateRelatorio()
  }, [updateRelatorio])

  if (!relatorio) return null

  return (
    <Container>
      <Card style={{ width: '768px' }}>
        <Card.Content>
          <Titulo>
            Relatório placa <TituloPlaca>{relatorio?.placa}</TituloPlaca>
          </Titulo>
          <BlocoConteudo titulo='Operador'>
            <BlocoConteudo.Item label='Nome e CPF' value={`${relatorio?.operador.nome} / ${relatorio?.operador.cpf}`} />
          </BlocoConteudo>
          <BlocoConteudo titulo='Condutor'>
            <BlocoConteudo.Item label='Nome e CPF' value={`${relatorio?.condutor.nome} / ${relatorio?.condutor.cpf}`} />
          </BlocoConteudo>
          <BlocoConteudo titulo='Emplacadora'>
            <BlocoConteudo.Item label='CNPJ' value={relatorio?.emplacadora?.cnpj} />
            <BlocoConteudo.Item label='Dados do fabricante' value={relatorio?.fabricante?.cnpj} />
            <BlocoConteudo.Item label='Nome fantasia' value={relatorio?.emplacadora?.nomeFantasia} />
            <BlocoConteudo.Item label='Razão Social' value={relatorio?.emplacadora?.razaoSocial} />
          </BlocoConteudo>
          <BlocoConteudo titulo='Serviço'>
            <BlocoConteudo.Item label='Data e hora da finalização' value={format(new Date(relatorio.dataFinal), "dd/MM/yyyy 'às' hh:mm'h'")} />
          </BlocoConteudo>
          <BlocoConteudo titulo='Nota fiscal'>
            <BlocoConteudo.Link label='Nota fiscal de venda' value='http://www.youtube.com.br' />
          </BlocoConteudo>
          <BlocoConteudo titulo='Localização do serviço'>
            <BlocoConteudo.Mapa lat={relatorio?.latitude} lng={relatorio?.longitude} />
          </BlocoConteudo>
          <BlocoConteudo titulo='Foto do emplacador'>
            <BlocoConteudo.Foto caption='original' value={relatorio?.operador.fotoDetran} />
            <BlocoConteudo.Foto value={relatorio?.validacaoFacialFinal} />
          </BlocoConteudo>
          <BlocoConteudo titulo='Foto do condutor'>
            <BlocoConteudo.Foto caption='original' value={relatorio?.condutor?.fotoDetran} />
            <BlocoConteudo.Foto value={relatorio?.condutor?.fotoCapturada} />
          </BlocoConteudo>
          <BlocoConteudo titulo='Foto placa dianteira e QR Code'>
            <BlocoConteudo.Foto value={relatorio?.capturaDianteira} />
            <BlocoConteudo.Foto value={relatorio?.capturaPlacaDianteira} />
          </BlocoConteudo>
          <BlocoConteudo titulo='Foto placa traseira e QR Code'>
            <BlocoConteudo.Foto value={relatorio?.capturaTraseira} />
            <BlocoConteudo.Foto value={relatorio?.capturaPlacaTraseira} />
          </BlocoConteudo>
          <BlocoConteudo titulo='Foto do chassi'>
            <BlocoConteudo.Foto value={relatorio?.capturaChassi} />
          </BlocoConteudo>
        </Card.Content>
      </Card>
    </Container>
  )
}

export default Relatorio
