import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { authReducer, IState as IStateAuth } from '../auth/AuthStore'

export interface IStateRedux {
  auth: IStateAuth,
}

export const store = configureStore({
  reducer: {
    auth: authReducer,
  },
  middleware: [...getDefaultMiddleware()],
  // middleware: [...getDefaultMiddleware(), save()],
  // preloadedState: load(),
})
