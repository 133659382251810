// import CommonStore from './stores/CommonStore'
import axios from 'axios'

import { UsuarioModel } from '../models/UsuarioModel'

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3500/api'
axios.defaults.baseURL = process.env.REACT_APP_API_URL || 'http://localhost:3500/api'
axios.defaults.headers = { platform: 'APP' }
export interface IQueryParams {
  order?: 'ASC' | 'DESC'
  orderBy?: string
  orderByEntity?: string
  offset?: number
  limit?: number
}

export interface ICounter<T> {
  count: number
  rows: T[]
}

const handleErrors = (err: any) => {
  let _setToken: any
  let _setError: any

  if (err && err.response && err.response.status === 401) {
    _setToken('')
    _setError('Você não tem permissão para acessar esse recurso')
    // LoginStore.logout()
    // LoginStore.setError('Você não tem permissão para acessar esse recurso')
  }
  if (err.response && err.response.data && err.response.data.message) err.message = err.response.data.message
  // _setIsLoading(false)
  throw err
}

const responseBody = (res: any) => {
  // _setIsLoading(false)
  return res.data
}

const getHeaders = () => {
  const token = localStorage.getItem('token')
  if (token) return { Authorization: `Bearer ${token}` }
  return {}
}
// let _setIsLoading: any

const requests = {
  delete: (url: string) => {
    // _setIsLoading(true)
    return axios
      .delete(apiUrl + url, { headers: getHeaders() })
      .then(responseBody)
      .catch(handleErrors)
  },
  get: (url: string, params?: any) => {
    // _setIsLoading(true)
    return axios
      .get(apiUrl + url, { params, headers: getHeaders() })
      .then(responseBody)
      .catch(handleErrors)
  },
  put: (url: string, body: any) => {
    // _setIsLoading(true)
    return axios
      .put(apiUrl + url, body, { headers: getHeaders() })
      .then(responseBody)
      .catch(handleErrors)
  },
  post: (url: string, body: any, upload = false) => {
    const headers: any = getHeaders()
    if (upload) headers['Content-Type'] = 'multipart/form-data'
    // _setIsLoading(true)
    return axios
      .post(apiUrl + url, body, { headers })
      .then(responseBody)
      .catch(handleErrors)
  },
}

const Usuarios = {
  login: (email: string, senha: string): Promise<{ token: string }> =>
    axios.post('/usuarios/login', { email, senha }).then(x => x.data),
  validateSenha: (senha: string) =>
    requests.post('/usuarios/validate-senha', { senha }),
  revalidaToken: (usuarioId: number): Promise<{ token: string }> =>
    requests.get(`/usuarios/revalida-token/${usuarioId}`),
  getByConta: (contaId: number): Promise<UsuarioModel[]> =>
    requests.get(`/usuarios/conta/${contaId}`),
  createOrUpdate: (usuario: UsuarioModel): Promise<UsuarioModel> =>
    requests.post('/usuarios', usuario),
  delete: (usuarioId: number): Promise<boolean> =>
    requests.delete(`/usuarios/${usuarioId}`),
  getByCpf: (cpf: string): Promise<UsuarioModel> =>
    requests.get(`/usuarios/cpf/${cpf}`),
  updateSenha: (usuarioId: number, senha: string, { senhaAtual, token }: { senhaAtual?: string, token?: string }): Promise<boolean> =>
    requests.put('/usuarios/senha', { usuarioId, senha, senhaAtual, token }),
  getByToken: (token: string): Promise<UsuarioModel> =>
    requests.get(`/usuarios/token/${token}`),
  createTokenRecuperaSenha: (email: string): Promise<boolean> =>
    requests.put('/usuarios/token', { email }),
  deleteFromConta: (usuarioId: number, contaId: number) =>
    requests.delete(`/usuarios/${usuarioId}/conta/${contaId}`),
  recuperarSenha: (cpf: string): Promise<boolean> =>
    axios.post('/usuarios/recuperasenha', { cpf }),
  alterarSenhaToken: (senha: string, token: string): Promise<boolean> =>
    axios.post('/usuarios/alterasenha/token', { senha, token }),
  alterarSenha: (usuarioId: string, senhaAtual: string, novaSenha: string): Promise<boolean> =>
    axios.post('/usuarios/alteraSenha', { usuarioId, novaSenha, senhaAtual }),
}

export default {
  Usuarios,
}
