import GoogleMapReact from 'google-map-react'
import { Grid, Header } from 'semantic-ui-react'
import React from 'react'
import styled from 'styled-components'
import TituloRelatorioSvg from './TituloRelatorioSvg'

const BlocoConteudoTitulo = styled(Header)`
  font-family: Rubik!important;
  font-style: normal!important;
  font-weight: bold!important;
  font-size: 16px!important;
  line-height: 19px!important;
  letter-spacing: 0.01em!important;
  color: #000723!important;
`

const BlocoConteudoTexto = styled('p')`
  font-family: Rubik!important;
  font-style: normal!important;
  font-weight: normal!important;
  font-size: 16px!important;
  line-height: 19px!important;
  color: #000723!important;
`

const BlocoConteudoLink = styled('a')`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  color: #2F80ED;
`

const BlocoConteudo = ({ titulo, children }: { titulo, children }) => {
  const itens = React.Children.toArray(children)
  return (
    <>
      <BlocoConteudoTitulo>
        <TituloRelatorioSvg /> {titulo}
      </BlocoConteudoTitulo>
      <Grid columns={itens.length >= 2 ? 2 : 1}>
        <Grid.Column>
          {itens.filter((_item, i) => i % 2 === 0)}
        </Grid.Column>
        {
          itens.length >= 2 && <Grid.Column>
            {itens.filter((_item, i) => i % 2 !== 0)}
          </Grid.Column>
        }
      </Grid>
    </>
  )
}

BlocoConteudo.Item = ({ label, value }: { label, value }) => {
  return (
    <>
      <BlocoConteudoTexto>
        {label}: {value}
      </BlocoConteudoTexto>
    </>
  )
}

BlocoConteudo.Link = ({ label, value }: { label, value }) => {
  return (
    <>
      <BlocoConteudoLink target='_BLANK' href={value}>{label}</BlocoConteudoLink>
    </>
  )
}

const Caption = styled('div')`
  margin-top: 18px;
  width: 100%;
  text-align: center;

  span {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }
`

BlocoConteudo.Foto = ({ value, caption }: { value: string, caption?: string }) => {
  return (
    <div>
      <img width='320' height='280' src={value} alt={caption || ''} />
      { caption && <Caption><span>{caption}</span></Caption>}
    </div>
  )
}

BlocoConteudo.Mapa = ({ lat, lng }: { lat, lng }) => {
  return (
    <div>
      <div style={{ height: '281px', width: '681px' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyB61am6KCqvxs4Uzpy__zapTRClhx0x44c' }}
          defaultCenter={{ lat, lng }}
          defaultZoom={14}
        >
        </GoogleMapReact>
      </div>
    </div>
  )
}

export default BlocoConteudo
