import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit'
import axios from '../common/axios'
import { IRequest } from '../common/IRequest'
import jwtDecode from 'jwt-decode'
import { IStateRedux } from '../common/ReduxStore'
import { toast } from 'react-toastify'

export interface IState {
  token: string | null,
  senhaMudada: boolean,
  request: IRequest,
}

const token = localStorage.getItem('token')

export const initialState: IState = {
  token: token !== 'undefined' && token ? token : '',
  senhaMudada: false,
  request: {
    fetching: false,
    errorCode: null,
    message: '',
  },
}

const { reducer: authReducer, actions } = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    loginFetch (state: IState): IState {
      state.request.fetching = false
      return state
    },
    loginSuccess (state: IState, { payload }: PayloadAction<{ token:any }>) {
      state.request = { ...initialState.request, fetching: false }
      state.token = payload.token
    },
    loginError (state: IState, { payload }: any) {
      state.request = {
        errorCode: payload.errorCode,
        message: payload.message,
        fetching: false,
      }
    },
    logout (state: IState) {
      state.token = null
    },
    recuperarSenha (state: IState): IState {
      state.request.fetching = true
      return state
    },
    recuperarSenhaSuccess (state: IState) {
      state.request = { ...initialState.request, fetching: false }
    },
    recuperarSenhaError (state: IState, { payload }: any) {
      state.request = {
        errorCode: payload.errorCode,
        message: payload.message,
        fetching: false,
      }
    },
    mudarSenha (state: IState): IState {
      state.request.fetching = true
      return state
    },
    mudarSenhaSuccess (state: IState) {
      state.request = { ...initialState.request, fetching: false }
      state.senhaMudada = true
    },
    mudarSenhaError (state: IState, { payload }: any) {
      state.request = {
        errorCode: payload.errorCode,
        message: payload.message,
        fetching: false,
      }
    },
  },
})

const authActions = {
  ...actions,
  login ({ email, senha }: { email: string, senha: string }) {
    return async (dispatch: any) => {
      dispatch(actions.loginFetch())
      try {
        const { token } = await axios.Usuarios.login(email, senha)
        dispatch(actions.loginSuccess({ token }))
      } catch (error) {
        dispatch(actions.loginError({ errorCode: 400, message: 'Falha ao realizar login' }))
        toast(error.response?.data?.message || 'Falha ao realizar login')
      }
    }
  },
  recuperaSenha ({ cpf } : { cpf: string }) {
    return async (dispatch: any) => {
      dispatch(actions.recuperarSenha())
      try {
        await axios.Usuarios.recuperarSenha(cpf)
        dispatch(actions.recuperarSenhaSuccess())
        toast('E-mail de recuperação de senha enviado')
      } catch (error) {
        dispatch(actions.recuperarSenhaError(error))
        toast('Falha ao enviar o e-mail de recuperação de senha')
      }
    }
  },
  mudarSenhaToken ({ senha, token } : { senha: string, token: string }) {
    return async (dispatch: any) => {
      dispatch(actions.mudarSenha())
      try {
        await axios.Usuarios.alterarSenhaToken(senha, token)
        dispatch(actions.mudarSenhaSuccess())
        toast('Senha alterada com sucesso')
      } catch (error) {
        dispatch(actions.mudarSenhaError(error))
        toast('Erro ao alterar senha')
      }
    }
  },
  alterarSenha ({ usuarioId, senhaAtual, novaSenha } : { usuarioId: string, senhaAtual: string, novaSenha: string }) {
    return async (dispatch: any) => {
      dispatch(actions.mudarSenha())
      try {
        await axios.Usuarios.alterarSenha(usuarioId, senhaAtual, novaSenha)
        dispatch(actions.mudarSenhaSuccess())
        toast('Senha alterada com sucesso')
      } catch (error) {
        dispatch(actions.mudarSenhaError(error))
        toast(error.response?.data?.message ?? 'Erro ao alterar senha')
      }
    }
  },
}

export const getUsuarioLogado = createSelector(
  (s: IStateRedux) => s.auth.token,
  (token) => jwtDecode(token as any)
)

export {
  authReducer,
  authActions,
}
