import { Container, Form, Button } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import React from 'react'
import styled from 'styled-components'
import { TextField } from '../common/TextField'
import { bindActionCreators } from 'redux'
import { authActions } from './AuthStore'

export interface IValues {
  email: string
  pwd: string
}

export const Login = () => {
  const { Container } = Login
  const dispatch = useDispatch()
  const actions = bindActionCreators(authActions, dispatch)

  const formik = useFormik({
    initialValues: { email: '', senha: '' },
    onSubmit: (values) => {
      const email = values.email
      const senha = values.senha
      actions.login({ email, senha })
    },
  })

  return (
    <Container text>
      <h1>QR Plate</h1>
      <FormikProvider value={formik}>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Field>
            <label>E-mail</label>
            <TextField name="email" placeholder="E-mail" />
          </Form.Field>
          <Form.Field>
            <label>Senha</label>
            <TextField name="senha" type="password" placeholder="Senha" />
          </Form.Field>
          <Button size="large" type="submit">Entrar</Button>
        </Form>
      </FormikProvider>
    </Container>
  )
}
Login.Container = styled(Container)`
  font-family: "Roboto Condensed", sans-serif;

  @media (min-width: 426px) and (min-height: 720px) {
    font-size: 1.3em;
  }

  h1 {
    text-align: "center";
  }
  background: white;
  box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 1em;
  margin: 1em;
  @media (min-width: 425px) {
    padding: 2em;
    margin: 2em;
    width: 800px;
    max-width: calc(100vw - 4em);
    margin: 0 auto;
    background: white;
  }
  label {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    input {
      background: #ffffff;
      border: 1px solid #ededed;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1),
        0 1px 3px 0 rgba(0, 0, 0, 0.08);
      transition: 0.35s box-shadow;
      border-radius: 3px;
      height: 3em;
      padding-left: 1em;
      width: 100%;
    }
  }
`
Login.ImgMarca = styled('div')`
  text-align: center;
  img {
    width: 250px;
  }
  @media (min-width: 426px) and (min-height: 720px) {
    img {
      width: 350px;
    }
  }

  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-around;
  background: none;
  box-shadow: none;
`
Login.EsqueceuSenha = styled('div')`
  text-align: right;
  margin-bottom: 2em;
`
