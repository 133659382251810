import React from 'react'
import { useField } from 'formik'
import InputMask from 'react-input-mask'

export const TextField = React.memo((props: any) => {
  const [field, meta] = useField(props.name)
  return (
    <div className="form-control-container">
      <label>
        {props.label}
        <input
          {...field}
          {...props}
          onChange={e => {
            if (props.onChange) props.onChange(e)
            field.onChange(e)
          }}
          className={`${props.className} form-control`}
          style={{ marginBottom: 0 }}
        />
      </label>
      {
        meta.touched && meta.error
          ? (<div style={{ textAlign: 'right', color: 'red' }}>{meta.error}</div>)
          : null
      }
    </div>
  )
})

export const MaskedTextField = ({ mask, onChange, ...props }: any) => {
  return (
    <InputMask mask={mask} onChange={onChange}>
      {inputProps => <TextField {...props} {...inputProps} />}
    </InputMask>
  )
}
